import { FC, Fragment, useMemo } from "react";
import { ShouldRender } from "@remotebase/components";
import { SortIcon } from "assets/icons";
import { RefProps, RefTableColumns, paginationDataLimit } from "utils";
import { IReferralData } from "state/referral";
import EmptyTrackerTable from "./RefEmptyTable";
import * as Styled from "./styles";
import RefPagination from "../refPagination";

type Props = {
  filteredTalentData: IReferralData;
  setFilteredTalentData: (IReferralData) => void;
  sortTalentByDate: RefProps["actions"]["sortTalentByDate"];
};
export const RefTrackerTable: FC<Props> = ({
  filteredTalentData,
  sortTalentByDate,
  setFilteredTalentData,
}) => {
  const isPaginated = useMemo(
    () => (filteredTalentData && Math.ceil(filteredTalentData?.length / paginationDataLimit)) ?? 0,
    [filteredTalentData],
  );

  const handleSort = (): void => {
    sortTalentByDate(filteredTalentData, setFilteredTalentData);
  };
  return (
    <Styled.RefTableWrapper>
      <Styled.RefTable responsive={!!filteredTalentData?.length} $isPaginated={isPaginated > 1}>
        <thead>
          <tr>
            <th>{RefTableColumns.Email}</th>
            <th>{RefTableColumns.Status}</th>
            <th>{RefTableColumns.PhoneNo}</th>
            <th>
              <Fragment>
                {RefTableColumns.Updated}
                <SortIcon onClick={handleSort} />
              </Fragment>
            </th>
            <th>{RefTableColumns.ReInviteButton}</th>
          </tr>
        </thead>
        <ShouldRender if={!!filteredTalentData?.length}>
          <RefPagination filteredTalentData={filteredTalentData} />
        </ShouldRender>
      </Styled.RefTable>
      <ShouldRender if={!filteredTalentData?.length}>
        <Styled.NoSignupsWrapper>
          <EmptyTrackerTable />
        </Styled.NoSignupsWrapper>
      </ShouldRender>
    </Styled.RefTableWrapper>
  );
};

export default RefTrackerTable;
