import { SidebarArrowIcon } from "assets/icons";
import { ProfileProps, AuthRoutes as Route, initialAvatarValue } from "utils";
import { Username } from "@remotebase/components";
import { upperFirst } from "lodash";
import { FC, useMemo, useState } from "react";
import { withProfile } from "state/profileSteps";
import SidebarProfileOption from "./SidebarProfileOption";
import * as Styled from "./styles";

const SidebarProfile: FC<ProfileProps> = ({ profileState: { data } }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [initials, setInitials] = useState(initialAvatarValue);
  const toggleOptions = (): void => setShowOptions(!showOptions);

  useMemo(() => {
    const name = data?.fullName?.split(" ");
    if (name) {
      const nameInitials = name?.[0].charAt(0) + name?.[1].charAt(0);
      setInitials(nameInitials.toUpperCase());
    }
  }, [data?.fullName]);

  return (
    <Styled.SidebarProfile>
      <Styled.SidebarDetails $show={showOptions}>
        {/* <SidebarProfileOption text="Profile" /> */}
        <SidebarProfileOption text="Log out" href={Route.Logout} />
      </Styled.SidebarDetails>

      <Styled.ProfileBox to="#" onClick={toggleOptions}>
        <Styled.AvatarBox>{initials}</Styled.AvatarBox>

        <Styled.ProfileInfo>
          <Styled.Name>
            <Username fullName={data?.fullName} />
          </Styled.Name>
          <Styled.Title>{upperFirst(data?.role.toLowerCase())}</Styled.Title>
        </Styled.ProfileInfo>

        <Styled.ArrowIconBox>
          <SidebarArrowIcon />
        </Styled.ArrowIconBox>
      </Styled.ProfileBox>
    </Styled.SidebarProfile>
  );
};

export default withProfile(SidebarProfile);
