import { DropdownButton } from "react-bootstrap";
import styled from "styled-components";

export const DropdownBtn = styled(DropdownButton)`
  width: 100%;

  &.show {
    & .dropdown-toggle {
      box-shadow: none;
      background-color: #fff;
      border: 1px solid #dddfe2;
      color: #474d58;
    }
  }
  & .dropdown-toggle {
    font-size: 16px;
    color: #757575;
    width: 100%;
    height: 52px;
    background-color: #fff;
    border: 2px solid #dddfe2;
    padding: 8px 20px;
    border-radius: 6px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      height: 46px;
      font-size: 12px;
      padding: 4px 15px;
    }

    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: #fff !important;
      border: 1px solid #dddfe2 !important;
      color: #474d58 !important;
    }
  }
  & .dropdown-menu {
    border-radius: 6px;
    padding: 6px 0;
    width: 100%;
    left: 0 !important;
    box-shadow: 0px 0px 14px rgb(0 0 0 / 10%);
  }
  & .dropdown-item {
    color: #474d58;
    font-size: 16px;
    padding: 6px 12px;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      padding: 5px 10px;
    }

    &:hover,
    &:focus,
    &.active {
      color: #474d58;
      background-color: #e9ecef;
    }
  }
`;

export default DropdownBtn;
