import { FC, Fragment } from "react";
import * as Styled from "./styles";

const AboutBtn: FC = () => {
  return (
    <Styled.WelcomeHeading>
      <Fragment>
        <Styled.PurpleFont>Welcome!</Styled.PurpleFont> Let’s create your profile.
      </Fragment>
    </Styled.WelcomeHeading>
  );
};

export default AboutBtn;
