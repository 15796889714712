import { getQuery } from "hooks/utils";
import { createRecruiterProfile } from "@remotebase/amplify-constants/graphql/mutations";
import {
  CreateRecruiterProfileInput,
  CreateRecruiterProfileMutation,
  CreateRecruiterProfileMutationVariables,
} from "@remotebase/amplify-constants/API";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

type CreateRecruiterProfileReturn = {
  performAction: (payload: CreateRecruiterProfileInput) => void;
  data?: CreateRecruiterProfileMutation | null;
  loading: boolean;
};

export const useCreateRecruiterProfile = (): CreateRecruiterProfileReturn => {
  const [createProfile, { data, loading }] = useMutation<
    CreateRecruiterProfileMutation,
    CreateRecruiterProfileMutationVariables
  >(getQuery(createRecruiterProfile));

  const performAction = useCallback(async (payload: CreateRecruiterProfileInput) => {
    createProfile({ variables: { input: { ...payload } } });
  }, []);

  return {
    performAction,
    data,
    loading,
  };
};

export default useCreateRecruiterProfile;
