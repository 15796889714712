export enum UnAuthRoutes {
  Login = "/login",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  Register = "/register",
  Reverify = "/reverify",
  NotFound = "/404",
  Maintenance = "/maintenance",
}

export enum AuthRoutes {
  CreateProfile = "/createProfile",
  Dashbord = "/dashboard",
  Make1000 = "/dashboard",
  Logout = "/logout",
  NotFound = "/404",
}
