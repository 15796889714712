import { ReferralStages as RefStatus } from "@remotebase/constants";
import { FC } from "react";
import { withReferral } from "state/referral";
import { refBoxColor, RefProps, refTooltipText, refTrackerBoxTestId } from "utils";
import RefBoxSingle from "./SingleTrackerBox";
import * as Styled from "./styles";

export type ActiveType = {
  active: string;
  color: string;
};

export const RefTrackerBoxes: FC<RefProps> = ({
  data: { combinedRefData, selectedFilter },
  actions: { filterTalentByType, setSelectedFilter },
}) => {
  return (
    <Styled.REFERRAL_BOX_ROW>
      {Object.values(RefStatus)?.map((status: RefStatus, index) => (
        <RefBoxSingle
          key={index}
          color={refBoxColor[status]}
          text={status}
          number={combinedRefData[status]}
          tooltip={refTooltipText[status]}
          dataTestId={`${refTrackerBoxTestId}-${status}`}
          onClick={filterTalentByType}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ))}
    </Styled.REFERRAL_BOX_ROW>
  );
};

export default withReferral(RefTrackerBoxes);
