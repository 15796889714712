export const loginErrorHeading = "Login Error";
export const resendErrorHeading = "Resend Error";
export const signUpErrorHeading = "SignUp Error";
export const confirmSignUpErrorHeading = "Confirm SignUp Error";
export const authFailedErrorHeading = "Authentication Error";
export const passwordErrorHeading = "Password Error";
export const problemSolvingErrorHeading = "Problem Solving Error";
export const domainTestErrorHeading = "Doamin Test Error";
export const hackerRankUrlErrorHeading = "Hacker Rank Url Error";
export const updateTalentErrorHeading = "Update Talent Error";
export const hackerRankUrlErrorMessage =
  "You are already invited to this test by hackerrank. Kindly check your email.";
export const pendingTestResultErrorHeading = "Pending Results";
export const pendingTestResultErrorMessage = `It seems that you have tests with pending results.
  If you have just taken a test, it takes 10-15 minutes to update the results.
  However if you have the test open in a new tab, please take the test and you will be redirected back here.`;

export const refEmailLimitError = "Email list should not be more than 10 emails";
export const refDuplicateEmailError = "email(s) are duplicated";
export const refAlreadyRefError = "email(s) are already referred";
export const refEmailSentSuccessMessage =
  "Your word matters so much to us! We’ll keep you updated on your referral!";
export const invalidEmailMessage = "Emails should be valid and comma separated";
export const refInvalidHeading = "Referral";
export const refInviteEmailTitleError = "Re-Invite Error";
export const refInviteEmailTitleSuccess = "Re-Invite Success";
export const createReferralError = "Create Referral Error";
export const reInviteEmailSuccessMessage = "Reinvited Successfully!";
export const authFailedErrorMessage = "Your email is associated with a non Recruiter's Profile";
