import * as yup from "yup";
/* eslint-disable no-useless-escape */

export enum ContactForm {
  firstName = "first name",
  lastName = "last name",
  email = "email",
  phone = "phone",
}

export const nameRegex = /^[A-Za-z]*$/;
export const invalidName = "Name can not contain special characters, numbers or spaces";

export const ContactFormSchema = yup.object().shape({
  [ContactForm.firstName]: yup.string().required().matches(nameRegex, invalidName),
  [ContactForm.lastName]: yup.string().required().matches(nameRegex, invalidName),
  [ContactForm.email]: yup.string().email().required(),
  [ContactForm.phone]: yup.string(),
});

export const pageChangeIndex = 1;
export const defaultProfileExperience = "0";

export const firstNamePalceholder = "first name";
export const lastNamePlaceholder = "last name";

export enum SkillsNameMap {
  Graphql = "GraphQL",
  React = "React.js",
  Angular = "Angular",
  Vue = "Vue.js",
  NodeJs = "Node.js",
  PHP = "PHP",
  Oracle = "Oracle",
  DotNet = ".Net",
  Wordpress = "WordPress",
  Ror = "RoR",
  Python = "Python",
  Django = "Django",
  Javascript = "JavaScript",
  Typescript = "TypeScript",
  Csharp = "C#",
  CPlusPlus = "C++",
  Mysql = "MySQL",
  Postgres = "PostgreSQL",
  d3 = "D3.js",
  Aws = "AWS",
  MongoDb = "MongoDB",
  Docker = "docker",
  DevOps = "DevOps",
  Golang = "GO",
  Redux = "Redux",
  Express = "Express.js",
  ReactAndNode = "React.js + Node.js",
  Magento = "Magento",
  Java = "Java",
}

// make key names of this enum same as value names of its corresponding enum in API.ts
export enum InterestsMap {
  SoftwareInteghration = "Software Integration",
  ML = "Machine Learning",
  Devops = "DevOps",
}

export const professionList = [
  "Technical Recruiter",
  "Human Resource Professional",
  "Engineer",
  "Academic",
  "Community Advocate",
  "Other",
];

export const defaultDropdownValue = "Select Profession";
