import { RemotebaseWindow } from "hooks/types";
import { ApiHookReturnActionType } from "hooks/utils";
import { useCallback } from "react";
import { IProfile } from "state/profileSteps";
import { useAnalytics } from "utils";

export const useIdentify = (userId: string, attributes: IProfile): ApiHookReturnActionType => {
  const win: RemotebaseWindow = window;
  const cb = async (): Promise<void> => {
    if (useAnalytics) {
      await win.analytics?.identify(userId, attributes);
    }
  };

  const callback = useCallback(cb, [win.analytics, useAnalytics]);
  return { performAction: callback };
};

export default useIdentify;
