import { FC, useEffect, useMemo, useState } from "react";
import { IReferralData } from "state/referral";
import { debounce } from "lodash";
import SearchIcon from "./SearchIcon";
import { SearchBarContainer, SearchBarInput, SearchIconContainer } from "./styles";

type Props = {
  sourceTalentData: IReferralData;
  setTalentData: (data: IReferralData) => void;
};

const SearchBar: FC<Props> = ({ sourceTalentData, setTalentData }): JSX.Element => {
  const [input, setInput] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInput(e.target.value);
  };
  const handleSearch = (query: string): void => {
    const filteredData = sourceTalentData?.filter((data) => data?.email?.includes(query)) ?? [];
    setTalentData(filteredData);
  };

  const delayInMilliseconds = 300;
  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, delayInMilliseconds, { trailing: true }),
    [debounce, sourceTalentData],
  );

  useEffect(() => {
    if (!input) {
      setTalentData(sourceTalentData);
      return;
    }
    debouncedHandleSearch(input);
  }, [input]);

  const placeHolderText = "Search talents by email";
  return (
    <SearchBarContainer>
      <SearchIconContainer>
        <SearchIcon width="1em" height="1em" />
      </SearchIconContainer>
      <SearchBarInput placeholder={placeHolderText} value={input} onChange={handleChange} />
    </SearchBarContainer>
  );
};

export default SearchBar;
