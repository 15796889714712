import { SidebarType as Type, Sidebar, SidebarStatusIcons, AuthRoutes as Route } from "utils";
import { ISidebarMenu } from "./sidebar.interface";
import {
  CodeTestingIcon,
  Make250Icon,
  OverviewIcon,
  ProfileIcon,
  SettingsIcon,
} from "../../assets/icons";

export const sidebarIcons = {
  [Sidebar.Profile]: <ProfileIcon />,
  [Sidebar.Make1000]: <Make250Icon />,
  [Sidebar.Settings]: <SettingsIcon />,
  [Sidebar.SignUp]: <OverviewIcon />,
  [Sidebar.Resume]: <CodeTestingIcon />,
};

export const getStatus = (index: number, stageLevel: number): string => {
  if (stageLevel < 0 && index === Math.abs(stageLevel) - 1) {
    return SidebarStatusIcons.Cross;
  }
  if (index > -1 && index < Math.abs(stageLevel)) {
    return SidebarStatusIcons.Tick;
  }
  if (
    (stageLevel > -1 && index > stageLevel) ||
    (stageLevel < 0 && index > Math.abs(stageLevel) - 1)
  ) {
    return SidebarStatusIcons.Lock;
  }
  return SidebarStatusIcons.Normal;
};

export const sidebarRoutes = {
  [Sidebar.Resume]: Route.CreateProfile,
  [Sidebar.Make1000]: Route.Make1000,
};

export const SidebarProfileOptions = [
  Sidebar.DomainTesting,
  Sidebar.ProblemSolving,
  Sidebar.HRScreening,
  Sidebar.FinalInterview,
];
export const SidebarCreateProfileOptions = [Sidebar.Resume];

export const sidebarMenu: ISidebarMenu = {
  [Type.Dashboard]: [Sidebar.Make1000],
  [Type.Create_Profile]: [Sidebar.SignUp, SidebarCreateProfileOptions],
};
