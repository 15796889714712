import { Auth } from "aws-amplify";
import { ProfileProps, shouldUseIntercom } from "utils";
import { FC, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withProfile } from "state/profileSteps";
import { useIntercom } from "react-use-intercom";

export const LogoutPage: FC<ProfileProps> = ({ cleanProfileState }) => {
  const { update } = useIntercom();
  const history = useHistory();
  useEffect(() => {
    if (shouldUseIntercom) update();
    Auth.signOut();
    history.push("/login");
    cleanProfileState();
  }, []);
  return <Fragment />;
};

export default withProfile(LogoutPage);
