import { yupResolver } from "@hookform/resolvers/yup";
import { Country, CreateRecruiterInput, UserRoles } from "API";
import {
  AuthProps,
  ProfileProps,
  combineHOCs,
  ContactForm,
  ContactFormSchema,
  updateTalentErrorHeading,
  lastNamePlaceholder,
  firstNamePalceholder,
  emailPlaceholder,
  nextBtnTestId,
} from "utils";
import { BtnLoader, ProfileInput } from "components";
import { PhoneNumberInput, ShouldRender } from "@remotebase/components";
import { useRecruiter, useIdentify, useCreateRecruiterProfile } from "hooks";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { withAuth } from "state/auth";
import withError from "state/error/withErrorHoc";
import { IContactData, IProfile, IProfileName, withProfile } from "state/profileSteps";
import classNames from "classnames";
import { useSentry } from "hooks/sentry";
import { ErrorProps } from "@remotebase/constants";
import ProfessionSection from "../professionSection";
import * as Styled from "./styles";

interface Props extends AuthProps, ProfileProps, ErrorProps {}
const ContactSection: FC<Props> = (props: Props) => {
  const {
    authState: { email, userId },
    updateUserInfo,
    profileState: { data: profile },
    showError,
  } = props;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, isValid },
  } = useForm({ resolver: yupResolver(ContactFormSchema), mode: "onChange" });

  const { updateInfo, createInfo, data: recruiterData, error, isLoading } = useRecruiter();
  const { performAction: createProfile, data: profileData } = useCreateRecruiterProfile();
  const { sendError } = useSentry();
  const [professionDropdownSelect, setProfessioDropdownSelect] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [country, setCountry] = useState<Country>();
  const [info, setInfo] = useState<CreateRecruiterInput>();

  const { performAction: analyticsIdentifyUser } = useIdentify(
    profile?.id as string,
    profile as IProfile,
  );

  useEffect(() => {
    if (profileData?.createRecruiterProfile?.id) {
      if (profile?.id) {
        const updatedInfo = { id: profile.id, expectedVersion: profile.version };
        analyticsIdentifyUser();
        updateInfo({ variables: { input: { ...info, ...updatedInfo } } });
      } else {
        createInfo({
          variables: {
            input: { ...info, recruiterProfileId: profileData.createRecruiterProfile.id },
          },
        });
      }
    }
  }, [profileData]);

  const onSubmit = (data: IContactData): void => {
    const input: CreateRecruiterInput = {
      fullName: `${data[ContactForm.firstName]} ${data[ContactForm.lastName]}`,
      phone: `${data[ContactForm.phone]}`,
      email: data[ContactForm.email],
      role: UserRoles.RECRUITER,
      country: country as Country,
      id: userId,
    };
    setInfo(input);
    createProfile({
      currentEarnings: 0,
      profession: professionDropdownSelect || "Technical Recruiter",
    });
  };

  const splitName = (val: string): IProfileName => {
    const parts = val.split(" ");
    const firstName = parts.shift() || "";
    const lastName = parts.join(" ");
    return { firstName, lastName };
  };

  useEffect(() => {
    setValue(ContactForm.email, email);
  }, [email]);

  useEffect(() => {
    if (profile) {
      const { firstName, lastName } = splitName(profile?.fullName);
      const phoneNo = profile?.phone;
      setValue(ContactForm.firstName, firstName);
      setValue(ContactForm.lastName, lastName);
      setValue(ContactForm.phone, phoneNo);
      setPhoneNumber(phoneNo);
    }
  }, [profile]);

  useEffect(() => {
    if (recruiterData && email) {
      updateUserInfo(recruiterData as IProfile);
    } else if (error) {
      if (profile) {
        const { firstName, lastName } = splitName(profile?.fullName);
        const createRecruiterInput: CreateRecruiterInput = {
          fullName: `${firstName} ${lastName}`,
          phone: `${profile?.phone}`,
          email: email || "",
          role: UserRoles.RECRUITER,
          country: profile.country,
          id: userId,
        };
        sendError(error, { createRecruiterInput });
      }
      showError({ title: updateTalentErrorHeading, message: error?.message });
    }
  }, [recruiterData, error]);

  useEffect(() => {
    if (phoneNumber) setValue(ContactForm.phone, phoneNumber);
  }, [phoneNumber]);

  const isFormValid = useMemo(
    () => isValid && !!phoneNumber && !!professionDropdownSelect,
    [isValid, phoneNumber, professionDropdownSelect],
  );
  const isFormSubmitted = useMemo(() => isSubmitted || isLoading, [isSubmitted, isLoading]);
  return (
    <Fragment>
      <h2>
        What’s your<Styled.PurpleFont> name</Styled.PurpleFont> and contact details?
      </h2>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <ProfileInput
            label="First Name"
            type="text"
            inputProps={register(ContactForm.firstName)}
            errorMsg={errors[ContactForm.firstName]?.message}
            placeholder={firstNamePalceholder}
          />
          <ProfileInput
            label="Last Name"
            type="text"
            inputProps={register(ContactForm.lastName)}
            errorMsg={errors[ContactForm.lastName]?.message}
            placeholder={lastNamePlaceholder}
          />
        </div>
        <div className="row">
          <ProfileInput
            label="Email"
            type="email"
            value={email || ""}
            disabled={!!email}
            placeholder={emailPlaceholder}
            inputProps={register(ContactForm.email)}
            errorMsg={errors[ContactForm.email]?.message}
          />
          <PhoneNumberInput
            setCountry={setCountry}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
          />
        </div>
        <div>
          <ProfessionSection
            handleSelect={setProfessioDropdownSelect}
            dropdownSelect={professionDropdownSelect}
          />
        </div>
      </form>

      <div className="row row-20 align-items-center">
        <div className="col-auto mb-2">
          <Styled.NextBtn
            type="submit"
            name="commit"
            value="Next"
            id="submitForm"
            data-disable-with="Submit"
            onClick={handleSubmit(onSubmit)}
            className={classNames({ disabled: !isFormValid || isFormSubmitted })}
            disabled={!isFormValid || isFormSubmitted}
            data-testid={nextBtnTestId}
          >
            <ShouldRender if={!isFormSubmitted}>
              <Fragment>Next</Fragment>
            </ShouldRender>
            <ShouldRender if={isFormSubmitted}>
              <BtnLoader />
            </ShouldRender>
          </Styled.NextBtn>
        </div>
      </div>
    </Fragment>
  );
};

export default combineHOCs(withAuth, withProfile, withError)(ContactSection) as FC;
