import { ReferralStages as RefStatus } from "@remotebase/constants";
import { IReferralData, IRefStatus, IRefBoxColor } from "state/referral";

/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

export const referralPageHeading =
  "Refer top talents you know and earn $1,000 for every talent that gets hired through you.";
export const refTrackBoxHeading = "Track your referrals 🚀";
export const refCopyLinkLabel = "Here is your personal referral link:";
export const refLinkSocialsLabel = "Share your personal referral link on:";
export const trackyourProgressLabel = "Track your progress:";
export const refNoSignupsLabel = "No Signups";
export const refEmailBoxLabel = "Enter email addresses, separated by a comma:";

export const refCopyLinkButtonLabel = "Copy Link";
export const refShareUrl = "https://talent.remotebase.com?referral=";

export const initialRefStatusValues = {
  [RefStatus.Total]: 0,
  [RefStatus.Joinned]: 0,
  [RefStatus.ClearedInterviews]: 0,
  [RefStatus.ClearedPSTest]: 0,
  [RefStatus.FailedPSTest]: 0,
  [RefStatus.ClearedDomainTest]: 0,
  [RefStatus.FailedDomainTest]: 0,
  [RefStatus.Rejected]: 0,
  [RefStatus.Networked]: 0,
  [RefStatus.Hired]: 0,
  [RefStatus.ThreeMonthsCompleted]: 0,
};

export type RefProps = {
  data: {
    refDetails: IReferralData;
    combinedRefData: IRefStatus;
    emailList: Array<string>;
    filteredTalentData: IReferralData;
    selectedFilter?: string;
  };
  actions: {
    setRefDetails: (data: IReferralData) => void;
    addEmails: (emails: Array<string>) => void;
    updateCanReInvite: (email: string) => void;
    filterTalentByType: (type: RefStatus) => void;
    sortTalentByDate: (data?: IReferralData, setData?: (IReferralData) => void) => void;
    setFilteredTalentData: (data: IReferralData) => void;
    setSelectedFilter?: (filter: string) => void;
  };
};

export const refBoxColor: IRefBoxColor = {
  [RefStatus.Total]: "#FFAF2B",
  [RefStatus.Joinned]: "#FF4367",
  [RefStatus.ClearedDomainTest]: "#0891FF",
  [RefStatus.FailedDomainTest]: "#8CC84B",
  [RefStatus.ClearedPSTest]: "#672876",
  [RefStatus.FailedPSTest]: "#376E9D",
  [RefStatus.ClearedInterviews]: "#C1B280",
  [RefStatus.Rejected]: "#FF4367",
  [RefStatus.Networked]: "#0891FF",
  [RefStatus.Hired]: "#FFAF2B",
  [RefStatus.ThreeMonthsCompleted]: "#53D5BB",
};

export const refTooltipText: IRefBoxColor = {
  [RefStatus.Total]: "Total number of developers referred",
  [RefStatus.Joinned]:
    "This shows the number of candidates that have signed up but not attempted any test. (Number reduces as candidates proceed to take a test)",
  [RefStatus.ClearedDomainTest]:
    "This shows the number of candidates that have taken the Domain Test and passed",
  [RefStatus.FailedDomainTest]:
    "This shows the number of candidates who have taken the Domain Test but failed the test",
  [RefStatus.ClearedPSTest]:
    "This shows the number of candidates that have taken the Problem Solving (PS) test and passed",
  [RefStatus.FailedPSTest]:
    "This shows the number of candidates that took the Problem Solving (PS) test but failed the test",
  [RefStatus.ClearedInterviews]:
    "This shows the number of candidates that have passed the recruitment interview(s)",
  [RefStatus.Rejected]:
    "This shows the number of candidates that were rejected through the recruitment process at Remotebase",
  [RefStatus.Networked]:
    "This shows the number of candidates that were networked through the recruitment process at Remotebase",
  [RefStatus.Hired]:
    "This shows the number of candidates that were hired through the recruitment process at Remotebase",
  [RefStatus.ThreeMonthsCompleted]:
    "Hired candidates who have spent 3 months working at Remotebase",
};

export const enum RefTableColumns {
  Email = "Email",
  Status = "Status",
  Updated = "Updated",
  PhoneNo = "Contact No",
  ReInviteButton = "",
}

export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const emailSubject = "Someone in Remotebase referred you!";

export const createReferralQuery = `
mutation CreateReferral(
  $input: CreateReferralInput!
  $condition: ModelReferralConditionInput
) {
  createReferral(input: $input, condition: $condition) {
    id
    email
    createdAt
    updatedAt
    version
  }
}`;

export const listReferralsQueryWithParams = `
query ListReferrals(
  $filter: ModelReferralFilterInput
  $limit: Int
  $nextToken: String
) {
  listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      referrerId
      version
    }
    nextToken
  }
}`;

export const listReferralsQueryWithoutParams = `
query ListReferrals {
  listReferrals {
    items {
      id
      email
    }
    nextToken
  }
}`;

export const updateReferralQuery = `
  mutation UpdateReferral(
    $input: UpdateReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    updateReferral(input: $input, condition: $condition) {
      id
      referrerId
      email
      createdAt
      updatedAt
      version
    }
  }
`;

export const referralEmailLimit = 10;
export const referralEmailButtonText = "Send";
export const refDefaultStatus = "Hasn’t signed up";
export const paginationDataLimit = 5;
export const ResentInviteBtn = "Resend Invite";
export const refQuote =
  "Hi! Feels a bit out of nowhere, doesn’t it? Well, it is important! Remotebase is hiring! I believe you would be an ideal candidate to join our team of remote talents working with companies all the way from the Bay Area! Sign up now, don’t miss it!\n";
export const refPageLimit = 5;
export const referralBonus = "$1000";
