import { FC } from "react";
import { Dropdown, Row, Col } from "react-bootstrap";
import { defaultDropdownValue, professionList } from "utils";
import * as Styled from "./styles";

interface Props {
  handleSelect: (event: string) => void;
  dropdownSelect: string;
}

const ProfessionSection: FC<Props> = ({ dropdownSelect, handleSelect }) => {
  const getTitle = (): string => {
    return professionList?.find((e) => e === dropdownSelect)
      ? dropdownSelect
      : defaultDropdownValue;
  };
  return (
    <Row>
      <Col xs={12} md={5}>
        <Styled.DropdownBtn title={getTitle()} onSelect={handleSelect}>
          {professionList.map((item, index) => (
            <Dropdown.Item key={index} eventKey={item} active={item === dropdownSelect}>
              {item}
            </Dropdown.Item>
          ))}
        </Styled.DropdownBtn>
      </Col>
    </Row>
  );
};

export default ProfessionSection;
