import { useTrackReferral } from "hooks";
import { FC, Fragment, useEffect, useState } from "react";
import { ShouldRender } from "@remotebase/components";
import { RefBoxShimmer } from "shimmerPages";
import withError from "state/error/withErrorHoc";
import { withProfile } from "state/profileSteps";
import { IReferralData, withReferral } from "state/referral";
import {
  getShareUrl,
  ProfileProps,
  refInvalidHeading,
  RefProps,
  trackyourProgressLabel,
} from "utils";
import { useIntercom } from "react-use-intercom";
import { ErrorProps } from "@remotebase/constants";
import {
  RefTrackerBoxes,
  RefTrackerTable,
  RefEmail,
  RefCopyLink,
  RefSocialShareBtns,
} from "./components";
import { LinkSocialsText } from "./components/refSocialShareButtons/styles";
import SearchBar from "./components/searchBar";

const ReferralHandler: FC<ProfileProps & RefProps & ErrorProps> = ({
  data: { filteredTalentData: talentData },
  profileState: { data: profileData },
  actions: { setRefDetails, setFilteredTalentData, sortTalentByDate },
  showError,
}) => {
  const { data: referralList, isLoading, error } = useTrackReferral();
  const [referralUrl, setReferralUrl] = useState("");
  const { update } = useIntercom();
  const [tempTalentData, setTempTalentData] = useState<IReferralData>([]);

  useEffect(() => {
    setTempTalentData(talentData);
  }, [talentData]);

  useEffect(() => {
    if (profileData?.id) {
      setReferralUrl(getShareUrl(profileData?.id));
      update({ name: `${profileData?.fullName}  (${profileData?.role})` });
    }
  }, [profileData]);

  useEffect(() => {
    if (error) showError({ title: refInvalidHeading, message: error });
  }, [error]);

  useEffect(() => {
    if (!isLoading && !error && referralList) {
      setRefDetails(referralList);
      setFilteredTalentData(referralList);
    }
  }, [referralList, isLoading]);

  return (
    <Fragment>
      <RefCopyLink shareUrl={referralUrl} />
      <RefEmail />
      <RefSocialShareBtns shareUrl={referralUrl} />
      <LinkSocialsText>{trackyourProgressLabel}</LinkSocialsText>
      <ShouldRender if={!isLoading}>
        <Fragment>
          <RefTrackerBoxes />
          <SearchBar setTalentData={setTempTalentData} sourceTalentData={talentData} />
          <RefTrackerTable
            filteredTalentData={tempTalentData}
            setFilteredTalentData={setTempTalentData}
            sortTalentByDate={sortTalentByDate}
          />
        </Fragment>
      </ShouldRender>
      <ShouldRender if={isLoading}>
        <RefBoxShimmer />
      </ShouldRender>
    </Fragment>
  );
};

export default withProfile(withReferral(withError(ReferralHandler)));
