import styled from "styled-components";

export const SearchBarContainer = styled.div`
  width: 100%;
  display: flex;
  height: 3rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e2e2e2 !important;
  overflow: hidden;
`;

export const SearchIconContainer = styled.div`
  color: #8a8b9a;
  margin-right: 0.5rem;
  padding-bottom: 0.3rem;
`;

export const SearchBarInput = styled.input`
  flex: 1;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  height: 100%;
  border-radius: 0 !important;
  font-size: 16px !important;
  font-weight: normal !important;
  color: #34495e !important;
  font-family: "Poppins", sans-serif !important;
  :focus {
    outline: none;
  }
`;
