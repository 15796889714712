import withApolloProvider from "hooks/apollo/withApollo";
import React, { useState, useContext, useMemo } from "react";
import { ReferralStages as RefStatus } from "@remotebase/constants";
import { initialRefStatusValues, RefProps } from "utils";
import { RefDetailsContext } from "./referral.context";
import { IReferralStats, IReferralTalent } from "./referral.interface";

export function withReferral<T>(Component: React.FC<T & RefProps>): React.FC<T> {
  return withApolloProvider((props: T) => {
    const { refDetails, setRefDetails } = useContext(RefDetailsContext);
    const { filteredTalentData, setFilteredTalentData } = useContext(RefDetailsContext);
    const [order, setOrder] = useState("desc");
    const [selectedFilter, setSelectedFilter] = useState<string>(RefStatus.Total);

    const getStatusLength = (type: string): number => {
      return refDetails?.filter((e) => e?.status === type).length || 0;
    };

    const combinedRefData = useMemo(() => {
      const output = initialRefStatusValues;
      Object.values(RefStatus).forEach((status) => {
        output[status] = getStatusLength(status);
      });
      output[RefStatus.Total] = Object.values(RefStatus)
        .filter((e) => e !== RefStatus.Total)
        .reduce((partialSum, a) => partialSum + getStatusLength(a), 0);
      return output;
    }, [refDetails]);

    const emailList = useMemo(() => {
      const output = [] as Array<string>;
      refDetails?.forEach((ele) => {
        if (ele?.email) output.push(ele.email);
      });
      return output;
    }, [refDetails]);

    const addEmails = (emails: Array<string>): void => {
      const output = refDetails || [];
      const emailsData = [] as Array<IReferralStats>;
      emails.forEach((email) => emailsData.push({ email }));
      setRefDetails([...output, ...emailsData]);
    };

    const updateCanReInvite = (email: string): void => {
      const output = refDetails || [];
      const emailsData = [] as Array<IReferralStats>;
      output.forEach((ref) => {
        if (ref?.email === email) {
          emailsData.push({ email: ref.email, status: ref.status, canReinvite: false });
        } else if (ref) emailsData.push(ref);
      });
      setRefDetails([...emailsData]);
    };

    const filterTalentByType = (type: RefStatus): void => {
      if (type === RefStatus.Total) setFilteredTalentData(refDetails);
      else {
        setFilteredTalentData(refDetails?.filter((e) => e?.status === type) || []);
      }
    };

    const getDate = (user: IReferralTalent): Date => new Date(user?.updatedAt) || 0;

    const sortOnDate = (a: IReferralTalent, b: IReferralTalent): number =>
      Number(getDate(a)) - Number(getDate(b));

    const sortTalentByDate = (
      talentData = filteredTalentData,
      setTalentData = setFilteredTalentData,
    ): void => {
      const talentsWithDate = talentData?.filter((item: IReferralStats) => item?.updatedAt);
      const talentsWithoutDate = talentData?.filter((item: IReferralStats) => !item?.updatedAt);
      if (order === "asc") {
        talentsWithDate?.sort((a: IReferralTalent, b: IReferralTalent) => sortOnDate(a, b));
        setOrder("desc");
      } else {
        talentsWithDate?.sort((a: IReferralTalent, b: IReferralTalent) => sortOnDate(b, a));
        setOrder("asc");
      }
      setTalentData([...(talentsWithDate || []), ...(talentsWithoutDate || [])] || []);
    };

    const profileProps: RefProps = {
      data: {
        refDetails,
        combinedRefData,
        emailList,
        filteredTalentData,
        selectedFilter,
      },
      actions: {
        setRefDetails,
        addEmails,
        updateCanReInvite,
        filterTalentByType,
        sortTalentByDate,
        setFilteredTalentData,
        setSelectedFilter,
      },
    };
    return <Component {...props} {...profileProps} />;
  });
}
export default withReferral;
