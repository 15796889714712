import styled from "styled-components";

export const PerksListDiv = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    padding: 0;
    margin: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Empty = styled.div``;
