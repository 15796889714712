import { Sidebar, ProfileProps, SidebarProps, combineHOCs, sidebarItemTestId } from "utils";
import { FC, Fragment, useState, useEffect } from "react";
import { withProfile } from "state/profileSteps";
import {
  sidebarMenu,
  ISidebarElements,
  withSidebar,
  SidebarProfileOptions,
  getStatus,
  SidebarCreateProfileOptions,
} from "state/sidebar";
import SidebarItem from "./SidebarItem";
import * as Styled from "./styles";

interface Props extends ProfileProps, SidebarProps {}
const SidebarMenu: FC<Props> = ({ profileState: { data }, sidebarType }) => {
  const [profileCompletionLevel] = useState(0);

  const getElemIndex = (index: number, key?: string): string => `${key || ""} ${index}`;
  const getSidebarItem = (element: Sidebar, index: string): JSX.Element => {
    const profileIndex = SidebarProfileOptions.findIndex((val) => val === element);
    const createProfileIndex = SidebarCreateProfileOptions.findIndex((val) => val === element);
    return (
      <SidebarItem
        key={index}
        text={element}
        testingId={`${sidebarItemTestId}-${element}`}
        status={getStatus(Math.max(profileIndex, createProfileIndex), profileCompletionLevel)}
      />
    );
  };
  const getMenuObjects = (objValue: ISidebarElements, objKey?: string): Array<JSX.Element> => {
    const elems = [] as JSX.Element[];
    objValue.map((element, index) => {
      if (typeof element === "string") {
        elems.push(getSidebarItem(element, getElemIndex(index, objKey)));
      } else elems.push(...getMenuObjects(element, `subElem${index}`));
      return <Fragment />;
    });
    if (objKey)
      return [<Styled.SidebarSubMenu key={`subMenu_${objKey}`}>{elems}</Styled.SidebarSubMenu>];
    return elems;
  };
  const getSidebarMenu = (): Array<JSX.Element> => {
    const output = sidebarMenu[sidebarType] as ISidebarElements;
    if (output?.length) return getMenuObjects(output);
    return [];
  };

  useEffect(() => {
    // setProfileCompletionLevel(getStage());
  }, [data]);

  return <Fragment>{getSidebarMenu()}</Fragment>;
};

export default combineHOCs(withSidebar, withProfile)(SidebarMenu) as FC;
