import { FC, Fragment, useEffect, useState } from "react";
import {
  refEmailBoxLabel,
  emailPlaceholder,
  emailRegex,
  invalidEmailMessage,
  referralEmailLimit,
  refEmailLimitError,
  RefProps,
  refDuplicateEmailError,
  refAlreadyRefError,
  refInvalidHeading,
  referralEmailButtonText,
  refEmailTextAreaTestId,
  refEmailErrorTestId,
  refEmailSendButtonTestId,
  refEmailSentSuccessMessage,
} from "utils";
import { useInviteEmails, useAnalyticsEventCreator } from "hooks";
import { BtnLoader } from "components";
import { withReferral } from "state/referral";
import withError from "state/error/withErrorHoc";
import { ErrorProps, RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import { ShouldRender } from "@remotebase/components";
import * as Styled from "./styles";

const RefEmail: FC<RefProps & ErrorProps> = (props) => {
  const { actions, data: PropData, showError } = props;
  const { refProfile, isLoading, data, success } = useInviteEmails();
  const { performAction: analyticsTrackEvent } = useAnalyticsEventCreator();
  const [emailError, setEmailError] = useState("");
  const [emails, setEmails] = useState("");
  const [verifiedEmails, setVerifiedEmails] = useState<Array<string>>([]);

  const { addEmails } = actions;
  const { emailList: prevEmailList } = PropData;

  const setEmailsOfUser = (event): void => {
    setEmails(event.target.value);
    if (emailError) setEmailError("");
  };
  const getAlreadyRefEmails = (emailList: Array<string>): string =>
    emailList.filter((email) => prevEmailList.includes(email)).join(", ");
  const getDuplicateEmails = (emailList: Array<string>): string => {
    const duplicateEmails = [] as Array<string>;
    emailList.forEach((email: string) => {
      const isDuplicated = emailList.filter((ele) => ele === email).length > 1;
      if (isDuplicated) duplicateEmails.push(email);
    });
    return duplicateEmails.filter((v, i, a) => a.indexOf(v) === i).join(", ");
  };
  const isEmailTextVerified = (emailList: Array<string>): boolean =>
    emailList.some((ele) => !emailRegex.test(ele));
  const cleanUpEmails = (): Array<string> =>
    emails
      .split(/,\s*/)
      .map((element) => element.trim())
      .filter((e) => e.length > 0);

  const getEmailError = (emailList: Array<string>): string => {
    if (isEmailTextVerified(emailList)) return invalidEmailMessage;
    if (emailList.length > referralEmailLimit) return refEmailLimitError;
    const duplicateEmails = getDuplicateEmails(emailList);
    if (duplicateEmails.length) return `${duplicateEmails} ${refDuplicateEmailError}`;
    const alreadyRefEmails = getAlreadyRefEmails(emailList);
    if (alreadyRefEmails.length) return `${alreadyRefEmails} ${refAlreadyRefError}`;
    return "";
  };

  const sendMail = (): void => {
    const emailList = cleanUpEmails();
    const emailErrorData = getEmailError(emailList);
    if (emailErrorData.length) setEmailError(emailErrorData);
    else {
      setVerifiedEmails(emailList);
      refProfile({ variables: { emails: emailList } });
      analyticsTrackEvent(RemoteBaseAnalyticsEvents.RECRUITER_REFERRAL_INVITED_EMAILS, {
        metaData: JSON.stringify(emailList),
      });
    }
  };

  useEffect(() => {
    if (success) {
      showError({ title: refInvalidHeading, message: refEmailSentSuccessMessage });
      addEmails(verifiedEmails);
    }
  }, [success]);

  useEffect(() => {
    if (emailError) showError({ title: refInvalidHeading, message: emailError });
  }, [emailError]);

  useEffect(() => {
    if (!isLoading && data && emails.length) setEmails("");
  }, [isLoading, data]);

  return (
    <Styled.EmailListBox>
      <Styled.EmailListText>{refEmailBoxLabel}</Styled.EmailListText>
      <Styled.RefTextAreaBox>
        <Styled.RefTextArea
          placeholder={emailPlaceholder}
          value={emails}
          onChange={setEmailsOfUser}
          data-testid={refEmailTextAreaTestId}
        />
        <ShouldRender if={emailError.length}>
          <Styled.RefEmailError data-testid={refEmailErrorTestId}>
            {emailError}
          </Styled.RefEmailError>
        </ShouldRender>
        <Styled.PurpleBtn
          data-testid={refEmailSendButtonTestId}
          onClick={sendMail}
          disabled={!emails.length || isLoading}
        >
          <ShouldRender if={!isLoading}>
            <Fragment>{referralEmailButtonText}</Fragment>
          </ShouldRender>
          <ShouldRender if={isLoading}>
            <BtnLoader />
          </ShouldRender>
        </Styled.PurpleBtn>
      </Styled.RefTextAreaBox>
    </Styled.EmailListBox>
  );
};

export default withReferral(withError(RefEmail));
