import React, { useState } from "react";
import { AuthRoutes as Route, ProfileProps } from "utils";
import { withProfile } from "state/profileSteps";
import { ShouldRender } from "@remotebase/components";
import { withSidebar } from "state/sidebar";
import SidebarList from "./SidebarList";
import * as Styled from "./styles";
import SidebarTop from "./SidebarTop";
/* import SidebarBanner from "./SidebarBanner"; */
import SidebarProfile from "./SidebarProfile";
import SidebarLogoutBtn from "./SidebarLogoutBtn";

export type SidebarActiveType = {
  active: string;
};

export const Sidebar: React.FC<ProfileProps> = ({ profileState: { data } }) => {
  const [toggle, setToggle] = useState(false);
  const handleClick = (): void => {
    setToggle(!toggle);
  };
  return (
    <Styled.SidebarWrapper>
      <Styled.Sidebar active={toggle ? "active" : ""}>
        <SidebarTop handleClick={handleClick} toggle={toggle} />
        <Styled.SIDEBAR_WRAPPER>
          <Styled.SidebarInner>
            <Styled.SidebarLogo to={Route.Make1000}>
              <Styled.SidebarPic src="img/logo-sm.png" alt="" />
            </Styled.SidebarLogo>
            <SidebarList />
            <ShouldRender if={data?.fullName}>
              <SidebarProfile />
            </ShouldRender>
            <ShouldRender if={!data?.fullName}>
              <SidebarLogoutBtn />
            </ShouldRender>
          </Styled.SidebarInner>
        </Styled.SIDEBAR_WRAPPER>
      </Styled.Sidebar>
    </Styled.SidebarWrapper>
  );
};

export default withProfile(withSidebar(Sidebar));
