export const sidebarCaptionTestId = "sidebar-caption";
export const sidebarItemTestId = "sidebar-item";
export const sidebarReferralTestId = "sidebar-item-referral";
export const sidebarCloseBtnTestId = "sidebar-close-btn";
export const sidebarBurgerTestId = "sidebar-burger";
export const sidebarTopLogoTestId = "sidebartop-logo";
export const sidebarTopLogoBlackTestId = "sidebartop-logo-black";
export const sidebarTopLogoWhiteTestId = "sidebartop-logo-white";
export const sidebarTestProfileName = "Jane Doe";
export const sidebarTestLogoutText = "Log out";
export const sidebarTestActiveCss = "background: #6c5dd3";
export const sidebarTestDisabledCss = "user-select: none; cursor: default;";
