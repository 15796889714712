import withApolloProvider from "hooks/apollo/withApollo";
import SidebarLayout from "layouts/sidebar_layout";
import { MaintenancePage, NotFound, ReferralPage } from "pages";
import { Fragment, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import { ShouldRender } from "@remotebase/components";
import { CreateProfile, FullPageLoader } from "components";
import { withSidebar } from "state/sidebar";
import withProfile from "state/profileSteps/withProfileHoc";
import {
  ProfileProps,
  combineHOCs,
  AuthRoutes as path,
  SidebarProps,
  SidebarType,
  shouldShowMaintenence,
  UnAuthRoutes,
} from "utils";

interface Props extends ProfileProps, SidebarProps {}
const AuthRouter: React.FC<Props> = (props) => {
  const {
    profileState: { data, isLoading, error },
    setSidebarType,
  } = props;
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (shouldShowMaintenence) {
      history.replace(UnAuthRoutes.Maintenance);
    } else if (!isLoading) {
      setSidebarType(SidebarType.Dashboard);
      if (!data && pathname !== path.CreateProfile) {
        history.push(path.CreateProfile);
        setSidebarType(SidebarType.Create_Profile);
      } else if (pathname === path.CreateProfile) {
        if (data) history.push(path.Make1000);
        else setSidebarType(SidebarType.Create_Profile);
      }
    }
  }, [isLoading, data, error]);

  return (
    <Fragment>
      <ShouldRender if={isLoading}>
        <FullPageLoader />
      </ShouldRender>

      <ShouldRender if={!isLoading && data !== undefined}>
        <Switch>
          <Route exact path={UnAuthRoutes.Maintenance} component={MaintenancePage} />
          <ThemeProvider theme={theme}>
            <SidebarLayout>
              <Route exact path={path.Make1000} component={ReferralPage} />
              <Route exact path={path.CreateProfile} component={CreateProfile} />
              <Route exact path={path.NotFound} component={NotFound} />
            </SidebarLayout>
          </ThemeProvider>
        </Switch>
      </ShouldRender>
    </Fragment>
  );
};

export default combineHOCs(withProfile, withApolloProvider, withSidebar)(AuthRouter);
