import { defaultRoute, StyledProps } from "utils";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { SidebarListActiveType } from "state/sidebar";

export const SidebarText = styled.div`
  margin-right: auto;
  color: ${(props): StyledProps => props.theme.secondaryFontColor};
  user-select: none;
`;

export const SidebarIcon = styled.div`
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  font-size: 0;
  position: relative;
  background-color: transparent;

  @media (max-width: 1199px) {
    width: 56px;
    height: 56px;
    margin-right: 0;
    -webkit-transition: margin 0.25s;
    -o-transition: margin 0.25s;
    transition: margin 0.25s;
  }

  @media (max-width: 1023px) {
    margin-right: 21px;
  }

  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const SidebarItem = styled(Link)<SidebarListActiveType>`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  border-radius: 12px;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  margin: 2px 0;
  &:hover {
    text-decoration: none;
  }
  svg path,
  svg circle {
    stroke: ${(props): StyledProps => (props.$active ? "#fff" : "")};
  }

  ${(props): StyledProps =>
    props.$active
      ? css`
          background: #6c5dd3;
          color: #ffffff;
          position: relative;
          ${SidebarText} {
            color: #fff;
            text-decoration: none;
          }
          svg {
            background: transparent;
          }
        `
      : css`
          svg {
            background: #fff;
            transition-delay: 0.15s;
          }
        `}

  ${(props): StyledProps =>
    props.$disabled
      ? css`
          user-select: none;
          cursor: default;
          ${SidebarText} {
            color: #cbccd2ff;
          }
          svg {
            background: #fff;
          }
          svg path {
            stroke: #cbccd2ff;
          }
        `
      : css``}

      cursor:${(props): StyledProps =>
    props.$pointer === defaultRoute ? "default" : " pointer !important"};

  @media (max-width: 1023px) {
    padding-left: 0;
    &:hover {
      color: #808191;
    }
  }

  @media (max-width: 767px) {
    padding-left: 3px;
  }
`;
