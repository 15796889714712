import { FC } from "react";
import {
  refCopyLinkBoxTestId,
  refCopyLinkButtonLabel,
  refCopyLinkButtonTestId,
  refCopyLinkLabel,
} from "utils";
import { useAnalyticsEventCreator } from "hooks";
import { RemoteBaseAnalyticsEvents } from "@remotebase/constants";
import * as Styled from "./styles";

interface Props {
  shareUrl: string;
}
export const RefCopyLink: FC<Props> = ({ shareUrl }) => {
  const { performAction: analyticsTrackEvent } = useAnalyticsEventCreator();

  const copyToClipboard = (): void => {
    navigator.clipboard.writeText(shareUrl);
    analyticsTrackEvent(RemoteBaseAnalyticsEvents.RECRUITER_REFERRAL_COPIED_URL);
  };
  return (
    <Styled.PersonalLinkBox>
      <Styled.EmailListText>{refCopyLinkLabel}</Styled.EmailListText>
      <Styled.CopyLinkBox data-testid={refCopyLinkBoxTestId}>
        <input type="text" value={shareUrl} disabled />
        <Styled.PurpleBtn data-testid={refCopyLinkButtonTestId} onClick={copyToClipboard}>
          {refCopyLinkButtonLabel}
        </Styled.PurpleBtn>
      </Styled.CopyLinkBox>
    </Styled.PersonalLinkBox>
  );
};

export default RefCopyLink;
