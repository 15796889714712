import { ILandingSkillsList } from "state/landing";
import { imageUrl } from "./imagesUrls";

export const landingNavItems = [
  { text: "About", href: "#aboutUs" },
  { text: "Skills", href: "#skills" },
  { text: "Process", href: "#process" },
];

export const LandingSocialLinks = {
  facebook: "https://www.facebook.com/RemotebaseHQ",
  youtube: "https://youtube.com/channel/UCSNQhZZpw6bpe6_-5lvAESQ",
  instagram: "https://www.instagram.com/remotebasehq/",
};

export const LandingSkillsList: ILandingSkillsList[] = [
  { dataAos: "fade-left", name: "React.js", logo: imageUrl.logoJobsReact },
  { dataAos: "fade-left", name: "Node.js", logo: imageUrl.logoJobsNode },
  { dataAos: "fade-left", name: "Python", logo: imageUrl.logoJobsPython },
  { dataAos: "fade-left", name: "Ruby on Rails", logo: imageUrl.logoJobsRails },
];
