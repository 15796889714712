import { useMemo } from "react";
import { IProfile, IProfileStepContext } from "./profileSteps.interface";

export const updateProfileStep = (context: IProfileStepContext, level: number): void => {
  const { currentStep, updateStep } = context;
  updateStep(currentStep < level ? currentStep + 1 : currentStep);
};

export const ProfileOptions = <InputType, FunctionType>(
  T: InputType,
  getTag: (e: FunctionType) => JSX.Element,
  dependency?: unknown,
): Array<JSX.Element> =>
  useMemo(() => Object.values(T).map((e: FunctionType) => getTag(e)), [T, dependency]);

export const getProfileLevel = (profile?: IProfile | null): number => {
  if (profile?.id) return 1;
  return 0;
};
