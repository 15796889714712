export enum Sidebar {
  Overview = "Overview",
  Profile = "Profile",
  DomainTesting = "Domain Test",
  HRScreening = "HR Screening",
  ProblemSolving = "Problem Solving",
  FinalInterview = "Final Tech Interview",
  Make1000 = "Make $1000 🔥 ",
  Settings = "Settings",
  SignUp = "Sign up",
  Resume = "Create Profile",
  FinishSignUp = "Finish sign-up",
}

export enum SidebarStatusIcons {
  Tick = "tick",
  Normal = "normal",
  Lock = "lock",
  Cross = "cross",
}
