/* eslint-disable max-len */
interface IProp {
  width: string;
  height: string;
}
function IconSearch({ width, height }: IProp): JSX.Element {
  return (
    <svg fill="currentColor" viewBox="0 0 16 16" height={height} width={width}>
      <path d="M11.742 10.344a6.5 6.5 0 10-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 001.415-1.414l-3.85-3.85a1.007 1.007 0 00-.115-.1zM12 6.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0z" />
    </svg>
  );
}

export default IconSearch;
