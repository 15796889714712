import { AuthProps, AuthRoutes, UnAuthRoutes } from "utils";
import { ShouldRender, ToastContainer } from "@remotebase/components";
import { FullPageLoader } from "components";
import { LogoutPage } from "pages";
import React, { Fragment, useEffect, useState } from "react";
import { Route, RouteProps, Switch, useHistory, useLocation } from "react-router-dom";
import { withAuth } from "state/auth";
import { ProfileProvider } from "state/profileSteps";
import SidebarProvider from "state/sidebar/sidebar.provider";
import withError from "state/error/withErrorHoc";
import { ErrorProps } from "@remotebase/constants";
import { AuthRoutesArray, mainRoutes, UnAuthRoutesArray } from "./RoutesConstants";

const MainRouter: React.FC<AuthProps & ErrorProps> = ({
  authState: { isLoading, isLoggedIn },
  ...rest
}) => {
  const [pathFound, handlePathFound] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof isLoggedIn === "boolean" && !isLoading) {
      if (isLoggedIn && !AuthRoutesArray.includes(pathname)) history.push(AuthRoutes.Make1000);
      else if (!isLoggedIn && !UnAuthRoutesArray.includes(pathname))
        history.push(UnAuthRoutes.Login);
      handlePathFound(true);
    }
  }, [isLoading, isLoggedIn]);

  return (
    <Fragment>
      <ToastContainer {...rest} />
      <ShouldRender if={!pathFound}>
        <FullPageLoader />
      </ShouldRender>

      <ShouldRender if={pathFound}>
        <ProfileProvider>
          <SidebarProvider>
            <Switch>
              <Route exact path="/logout" component={LogoutPage} />
              {mainRoutes.map((route: RouteProps, index: number) => (
                <Route key={`${index}`} {...route} />
              ))}
            </Switch>
          </SidebarProvider>
        </ProfileProvider>
      </ShouldRender>
    </Fragment>
  );
};

export default withError(withAuth(MainRouter));
