import { IRefStatus } from "state/referral";
import { ReferralStages as RefStatus } from "@remotebase/constants";

export const refEmailSendButtonTestId = "ref-email-send-button";
export const refEmailTextAreaTestId = "ref-email-text-area";
export const refEmailErrorTestId = "ref-email-error";
export const refInvalidEmail = "abc@asd";

export const refCopyLinkButtonTestId = "ref-copylink-button";
export const refCopyLinkBoxTestId = "ref-copylink-box";

export const refTwitterIconTestId = "ref-twitter-icon";
export const refFacebookIconTestId = "ref-facebook-icon";
export const refLinkedInIconTestId = "ref-linkedin-icon";

export const refTrackerBoxTestId = "ref-trackerbox-";
export const refCombinedDataTesting: IRefStatus = {
  [RefStatus.Total]: 300,
  [RefStatus.Joinned]: 23,
  [RefStatus.ClearedDomainTest]: 32,
  [RefStatus.FailedDomainTest]: 31,
  [RefStatus.ClearedPSTest]: 32,
  [RefStatus.FailedPSTest]: 18,
  [RefStatus.ClearedInterviews]: 32,
  [RefStatus.Hired]: 44,
  [RefStatus.ThreeMonthsCompleted]: 88,
};

export const refFilledTableTestId = "ref-tracker-table-filled";
export const refEmptyTableTestId = "ref-tracker-table-empty";
export const mockReferralData = [
  {
    email: "remote.test1@mailinator.com",
    status: RefStatus.Joinned,
  },
  {
    email: "remote.test2@mailinator.com",
    status: RefStatus.Hired,
  },
  {
    email: "remote.test3@mailinator.com",
    status: RefStatus.ThreeMonthsCompleted,
  },
  {
    email: "remote.test4@mailinator.com",
    status: RefStatus.ClearedInterviews,
  },
];
