import { useSignup } from "hooks";
import { AuthCommonLoginRegister } from "@remotebase/components";
import { AuthType, UserType } from "@remotebase/constants";

const SignUpForm: React.FC = () => {
  const {
    performAction,
    res: { isLoading },
  } = useSignup();

  return (
    <AuthCommonLoginRegister
      formProps={{ isLoading, performAction }}
      userType={UserType.Recruiter}
      authType={AuthType.SignUp}
    />
  );
};

export default SignUpForm;
