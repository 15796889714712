import { StyledProps } from "utils";
import { Link } from "react-router-dom";
import { SidebarOptionsView } from "state/sidebar";
import styled, { css } from "styled-components";
import { SidebarActiveType } from "..";

export const SidebarTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 140px;
  @media (max-width: 1023px) {
    padding: 0;
    height: 96px;
  }
  @media (max-width: 767px) {
    padding-left: 40px;
    border-bottom: 1px solid #e4e4e4;
    justify-content: flex-start;
  }
`;
export const SidebarTopLogo = styled(Link)`
  font-size: 0;
  @media (max-width: 1023px) {
    opacity: 0;
  }
`;
export const SidebarBurger = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 96px;
  height: 96px;
  padding: 20px;
  border-bottom: 1px solid transparent;
  font-size: 0;
  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: 32px;
    height: 2px;
    margin: 3px auto;
    border-radius: 1px;
    background: #1b1d21;
  }
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1023px) {
    border-color: #e4e4e4;
  }
  @media (max-width: 767px) {
    &.active {
      opacity: 0;
      pointer-events: none;
    }
    position: fixed;
    left: 256px;
    top: 0;
    border-bottom: 1px solid #e4e4e4;
    height: 96px;
    padding: 12px 24px;
    background-color: #fff;
    width: 100vw;
    opacity: 1;
    pointer-events: initial;
    &:before,
    &:after {
      margin-left: 0;
    }
  }
`;
export const SidebarDetails = styled.div<SidebarOptionsView>`
  background: #f7f7f7;
  border-radius: 12px;
  -webkit-transition: padding 0.25s, background 0.25s;
  -o-transition: padding 0.25s, background 0.25s;
  transition: padding 0.25s, background 0.25s;
  z-index: 1;
  position: absolute;
  bottom: 60px;
  width: 100%;
  padding: 10px;
  ${(props): StyledProps =>
    props.$show
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  &:before {
    content: "";
    position: absolute;
    top: 8px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 72px;
    height: 4px;
    border-radius: 2px;
    background: #e4e4e4;
  }
  @media (max-width: 1023px) {
    padding: 5px 0 17px;
    background: none;
  }
`;
export const SidebarLinkIcon = styled.div`
  width: 50px;
  margin: 0;
  padding-right: 6px;
  text-align: center;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  @media (max-width: 1023px) {
    width: 56px;
    margin-right: 24px;
    padding: 0;
  }
`;

export const SIDEBAR_WRAPPER = styled.div`
  max-height: 100%;
  padding: 0 20px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (max-width: 767px) {
    padding: 20px;
  }
`;
export const SidebarInner = styled.div`
  width: 215px;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
  transition: width 0.25s;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1023px) {
    width: 56px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const SidebarLogo = styled(Link)`
  font-size: 0;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0;
  overflow: hidden;
  margin: 0 -20px;

  @media (max-width: 1023px) {
    height: 80px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;
export const Sidebar = styled.div<SidebarActiveType>`
  position: fixed;
  top: 0;
  left: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 256px;
  height: 100vh;
  padding: 140px 0 0;
  background: #ffffff;
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;

  .switch {
    margin-right: auto;
  }
  @media (max-width: 1199px) {
    padding-top: 96px;
    bottom: 0;
    height: auto;
  }

  /* body.dark .sidebar {
    background: #242731;
    border-color: rgba(228, 228, 228, 0.1);
  } */
  ${(props): StyledProps =>
    props.active === "active"
      ? css`
          ${SidebarTop} {
            padding: 0;
          }
          .sidebar__icon {
            margin-right: 21px;
          }
          ${SIDEBAR_WRAPPER} ${SidebarLogo} {
            height: 80px;
          }
          ${SidebarDetails} {
            padding: 5px 0 17px;
            background: none;
          }
          ${SidebarLinkIcon} {
            width: 56px;
            padding: 0;
          }
          /* body.dark .sidebar.active .sidebar__burger {
            border-color: rgba(228, 228, 228, 0.1);
          } */
          @media (max-width: 1023px) {
            z-index: 10;
            width: 256px;
            .app__logo {
              width: 140px;
              overflow: hidden;
              margin-left: 1px;
            }
            .app__logo img {
              max-width: 100%;
              width: auto;
            }
            .switch_theme .switch__in {
              padding-left: 60px;
            }
            ${SidebarInner} {
              width: 100%;
            }
            ${SidebarBurger} {
              border-color: transparent;
            }
            ${SidebarTop} {
              padding-left: 40px;
              padding-right: 10px;
              justify-content: flex-start;
            }
            ${SidebarTopLogo} {
              opacity: 1;
            }
            .sidebar__icon {
              margin-right: 0;
            }
            .sidebar__caption {
              padding: 0 20px;
              text-align: left;
            }
            .sidebar__caption span {
              display: inline;
            }
            .sidebar__group:before {
              left: 20px;
              right: 20px;
            }
            ${SIDEBAR_WRAPPER} ${SidebarLogo} {
              height: 0;
            }
            .sidebar__item {
              padding-left: 3px;
            }
            .sidebar__counter {
              position: static;
              min-width: 24px;
              height: 24px;
              font-size: 12px;
            }
            .sidebar__user {
              padding: 0 20px;
            }
            .sidebar__bottom {
              padding: 0 40px;
            }
            .sidebar__bottom .switch_theme .switch__in {
              padding-left: 56px;
            }
            ${SidebarBurger} {
              border-color: transparent;
            }
          }
          @media (max-width: 767px) {
            width: 256px;
            -webkit-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            transform: translateX(-100%);

            .switch_theme .switch__in {
              padding-left: 56px;
            }

            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
            .sidebar__caption {
              padding-left: 15px;
            }
            .sidebar__item {
              padding-left: 0;
            }
            ${SidebarBurger} {
              border-color: #e4e4e4;
            }
          }
        `
      : css`
          @media (max-width: 1199px) {
            padding-top: 96px;
            overflow: hidden;
            -webkit-transition: width 0.25s, -webkit-transform 0.25s;
            transition: width 0.25s, -webkit-transform 0.25s;
            -o-transition: width 0.25s, transform 0.25s;
            transition: width 0.25s, transform 0.25s;
            transition: width 0.25s, transform 0.25s, -webkit-transform 0.25s;
          }
          @media (max-width: 1023px) {
            z-index: 10;
            width: 96px;
            .switch_theme .switch__in {
              padding-left: 60px;
            }
          }

          @media (max-width: 767px) {
            width: 256px;
            overflow: initial;
            -webkit-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
            transform: translateX(-100%);

            .switch_theme .switch__in {
              padding-left: 56px;
            }
          }
        `}
`;
export const SidebarWrapper = styled.div`
  display: flex;
`;
export const SidebarPic = styled.img`
  max-width: 100%;
`;
