import { FC, useState, useEffect } from "react";
import className from "classnames";
import { autoScrollOnCreateProfile, ProfileProps } from "utils";
import { ProfileStepContext, getProfileLevel, withProfile } from "state/profileSteps";
import { AboutBtn, ContactSection } from "./sections";

const CreateProfile: FC<ProfileProps> = ({ profileState: { data } }) => {
  const [currentStep, updateStep] = useState(0);

  const isActive = (level: number): string => className({ active: currentStep === level });

  useEffect(() => {
    autoScrollOnCreateProfile(currentStep);
  }, [currentStep]);

  useEffect(() => {
    updateStep(getProfileLevel(data));
  }, [data]);

  return (
    <ProfileStepContext.Provider value={{ currentStep, updateStep }}>
      <section data-step="0" className={isActive(0)}>
        <AboutBtn />
      </section>
      <section data-step="1" className={isActive(1)}>
        <ContactSection />
      </section>
    </ProfileStepContext.Provider>
  );
};

export default withProfile(CreateProfile);
