export const imageUrl = {
  logoRemotebase: "/images/logo-remotebase.png",
  logoRemotebaseWhiteTxt: "/images/logo-white.png",
  loginPeopleWorking: "/images/login_people-working.png",
  signupPeopleWorking: "/images/signup_people-working.png",
  entryCircle: "/images/entry-circle.svg",
  cube: "/images/cube.png",
  notepad: "/images/purple_notepad.png",
  waitlistAdd: "/images/waitlist_add.png",
  purpleNotepad: "/images/purple_notepad.png",
  waitlistClock: "/images/waitlist_clock.png",
  cloud: "/images/cloud.png",
  purpleSpeaker: "/images/purple_speaker.png",
  purpleFolder: "/images/purple_folder.png",
  sidebarPic: "/images/sidebar-pic.svg",
  logoReact: "/images/react-logo.svg",
  logoReactNode: "/images/react-node-logo.svg",
  logoJobsReact: "/images/landing/jobs-reactjs.svg",
  logoNode: "/images/nodejs-logo.svg",
  logoJobsNode: "/images/landing/jobs-nodejs.svg",
  logoAngular: "/images/angular-logo.svg",
  logoPython: "/images/python-logo.svg",
  logoJobsPython: "/images/landing/jobs-python.svg",
  logoGraphql: "/images/graphQl-logo.svg",
  logoJavasctipt: "/images/javascript-logo.svg",
  logoRails: "/images/rails-logo.svg",
  logoJobsRails: "/images/landing/jobs-ror.svg",
  logo: "/images/logo.svg",
  landingMain: "/images/landing/main-pic-1.png",
  landingMainArrowDown: "/images/landing/ArrowDown.svg",
  landingMain2: "/images/landing/main-pic-2.png",
  landingMain3: "/images/landing/main-pic-3.png",
  landingMain4: "/images/landing/main-pic-4.png",
  landingMain5: "/images/landing/main-pic-5.svg",
  landingArrowRight: "/images/landing/ArrowRight.svg",
  landingProcess: "/images/landing/process-pic-2.png",
  landingProcess2: "/images/landing/process-pic-3.png",
  landingProcess3: "/images/landing/process-pic-4.png",
  landingProcess4: "/images/landing/process-pic-5.png",
  landingTeam: "/images/landing/team-img.jpg",
  landingTalentPic: "/images/landing/talent-pic-1.png",
  landingTalentPic2: "/images/landing/talent-pic-2.png",
  landingTalentPic3: "/images/landing/talent-pic-3.png",
  landingTalentPic4: "/images/landing/talent-pic-4.png",
  landingTalentFig: "/images/landing/talent-figure.svg",
  footerFacebook: "/images/landing/logo-facebook.svg",
  footerYoutube: "/images/landing/logo-youtube.svg",
  footerInstagram: "/images/landing/logo-instagram.svg",
  trait: "/images/landing/work-pic-1.png",
  trait2: "/images/landing/work-pic-2.png",
  trait3: "/images/landing/work-pic-3.png",
  trait4: "/images/landing/work-pic-4.png",
  trait5: "/images/landing/work-pic-5.png",
  trait6: "/images/landing/work-pic-6.png",
  trait7: "/images/landing/work-pic-7.png",
  trait8: "/images/landing/work-pic-8.png",
  noSignup: "/images/referral-inbox.svg",
  logoDotnet: "/images/dotnet-icon.svg",
  logoIOS: "/images/ios-logo.svg",
  logoAndroid: "/images/android-logo.svg",
  logoGo: "/images/golang-logo.svg",
  logoDJango: "/images/django-logo.svg",
  logoAws: "/images/amazon-web-services-logo.svg",
  logoFlutter: "/images/flutter-logo.svg",
  google: "/images/google.svg",
};

export default imageUrl;
