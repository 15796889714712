import { FC, Fragment, ReactElement } from "react";
import { TrackerTableEntry } from "@remotebase/components";
import { IReferralData, IReferralStats, withReferral } from "state/referral";
import { refFilledTableTestId, RefProps } from "utils";
import ReInviteBtn from "./ReInviteBtn";

interface Props {
  children: ReactElement<unknown>;
  refEntries?: IReferralData;
}

export const FilledTrackerTable: FC<Props & RefProps> = ({
  refEntries,
  children,
  actions: { updateCanReInvite },
}) => {
  const handleEmail = (email: string): void => {
    updateCanReInvite(email);
  };
  const getInviteBtn = (email?: string | null, canReinvite?: boolean): JSX.Element => {
    if (canReinvite && email) return <ReInviteBtn email={email} onInvitation={handleEmail} />;
    return <Fragment />;
  };
  return (
    <tbody data-testid={refFilledTableTestId}>
      {refEntries?.map(
        (
          { email, status, updatedAt, canReinvite, phoneNo }: IReferralStats,
          index: number,
        ): JSX.Element => (
          <TrackerTableEntry
            key={`${email}-${index}`}
            email={email}
            phoneNo={phoneNo}
            status={status}
            updatedAt={updatedAt}
            canReinvite={canReinvite}
            reInviteBtn={getInviteBtn}
          />
        ),
      )}
      {children}
    </tbody>
  );
};

export default withReferral(FilledTrackerTable);
