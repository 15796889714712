import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CreateRecruiterMutation,
  CreateRecruiterMutationVariables,
  CreateTalentProfileWithUsdSalaryMutation,
  CreateTalentProfileWithUsdSalaryMutationVariables,
  UpdateRecruiterMutation,
  UpdateRecruiterMutationVariables,
  UpdateTalentProfileWithUsdSalaryMutation,
  UpdateTalentProfileWithUsdSalaryMutationVariables,
  GetRecruiterQuery,
  GetRecruiterQueryVariables,
} from "API";
import {
  createRecruiter,
  updateRecruiter,
  createTalentProfileWithUsdSalary,
  updateTalentProfileWithUsdSalary,
} from "graphql/mutations";
import { getRecruiter } from "graphql/queries";
import {
  ReturnPropsTalentProfile,
  UseRecruiterProfileProps,
  GetRecruiterProfileProps,
} from "hooks/types";
import { getQuery } from "hooks/utils/helpers";

export const useTalentProfile = (): ReturnPropsTalentProfile => {
  const [
    createProfile,
    { data: createProfileData, loading: createProfileLoading, error: createProfileError },
  ] = useMutation<
    CreateTalentProfileWithUsdSalaryMutation,
    CreateTalentProfileWithUsdSalaryMutationVariables
  >(getQuery(createTalentProfileWithUsdSalary));

  const [
    updateProfile,
    { data: updateProfileData, loading: updateProfileLoading, error: updateProfileError },
  ] = useMutation<
    UpdateTalentProfileWithUsdSalaryMutation,
    UpdateTalentProfileWithUsdSalaryMutationVariables
  >(getQuery(updateTalentProfileWithUsdSalary));

  return {
    createProfile,
    updateProfile,
    data:
      updateProfileData?.updateTalentProfileWithUsdSalary?.data ||
      createProfileData?.createTalentProfileWithUsdSalary?.data,
    isLoading: createProfileLoading || updateProfileLoading,
    error: createProfileError || updateProfileError,
  };
};

export const useRecruiter = (): UseRecruiterProfileProps => {
  const [createInfo, { data: createInfoData, loading: createInfoLoading, error: createInfoError }] =
    useMutation<CreateRecruiterMutation, CreateRecruiterMutationVariables>(
      getQuery(createRecruiter),
    );

  const [updateInfo, { data: updateInfoData, loading: updateInfoLoading, error: updateInfoError }] =
    useMutation<UpdateRecruiterMutation, UpdateRecruiterMutationVariables>(
      getQuery(updateRecruiter),
    );

  return {
    createInfo,
    updateInfo,
    data: updateInfoData?.updateRecruiter || createInfoData?.createRecruiter,
    isLoading: updateInfoLoading || createInfoLoading,
    error: createInfoError || updateInfoError,
  };
};

export const getRecruiterProfile = (): GetRecruiterProfileProps => {
  const [getProfile, { data, loading, error }] = useLazyQuery<
    GetRecruiterQuery,
    GetRecruiterQueryVariables
  >(getQuery(getRecruiter));
  const profileData = data?.getRecruiter || null;
  const errorData = error || (profileData ? undefined : new Error("No Recruiter Found"));
  return { loading, getProfile, profileData, error: errorData };
};
