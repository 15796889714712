import styled from "styled-components";

export const PerkList = styled.div`
  margin-bottom: 16px;

  @media (max-width: 575px) {
    margin-bottom: 16px;
  }
`;

export const PerkListText = styled.li`
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 767px) {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  @media (max-width: 575px) {
    display: flex;
  }
`;

export const BlueCircleDiv = styled.div`
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #2285ef;
  border: 1px solid #2285ef;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-right: 12px;
  font-size: 11px;
  color: #ffffff;
`;
