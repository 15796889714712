import { ShouldRender } from "@remotebase/components";
import { range } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";
import { IReferralData } from "state/referral";

import { FilledReferralTable } from "components/referral/components";
import { paginationDataLimit, refPageLimit } from "utils";
import * as Styled from "./styles";

type Props = {
  filteredTalentData: IReferralData;
};
const RefPagination: FC<Props> = ({ filteredTalentData }) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(-1);
  const [startingPage, setStartingPage] = useState(0);
  const [currentEmails, setCurrentEmails] = useState(filteredTalentData);

  const changePage = (changedVal: number): void => {
    const currentVal = currentPage + changedVal;
    setCurrentPage(currentVal);
    if (currentVal < startingPage || currentVal > startingPage + refPageLimit - 1) {
      setStartingPage((value) => value + changedVal);
    }
  };
  const nextPage = (): void => changePage(1);
  const prevPage = (): void => changePage(-1);
  const jumpToPage = (num: number): void => setCurrentPage(num);

  const pageLimit = useMemo(() => Math.min(refPageLimit, totalPages), [totalPages]);
  const pages = useMemo(() => {
    const pageArray = [] as JSX.Element[];
    range(startingPage, startingPage + pageLimit).forEach((num) => {
      pageArray.push(
        <Pagination.Item
          className="paginationNos"
          active={num === currentPage}
          key={num}
          onClick={(): void => jumpToPage(num)}
        >
          {num + 1}
        </Pagination.Item>,
      );
    });
    return pageArray;
  }, [totalPages, currentPage]);

  useEffect(() => {
    if (filteredTalentData) {
      setTotalPages(Math.ceil(filteredTalentData.length / paginationDataLimit));
      if (currentPage === -1) setCurrentPage(0);
    }
  }, [filteredTalentData]);

  useEffect(() => {
    if (currentPage > -1 && filteredTalentData) {
      const offset = currentPage * paginationDataLimit;
      setCurrentEmails(filteredTalentData.slice(offset, offset + paginationDataLimit));
    }
  }, [currentPage, filteredTalentData]);

  return (
    <FilledReferralTable refEntries={currentEmails}>
      <ShouldRender if={totalPages > 1}>
        <tr>
          <td>
            <Styled.Pagination>
              <Pagination>
                <Pagination.Item onClick={prevPage} disabled={currentPage === 0}>
                  Prev
                </Pagination.Item>
                {pages}
                <Pagination.Item onClick={nextPage} disabled={currentPage === totalPages - 1}>
                  Next
                </Pagination.Item>
              </Pagination>
            </Styled.Pagination>
          </td>
        </tr>
      </ShouldRender>
    </FilledReferralTable>
  );
};

export default RefPagination;
